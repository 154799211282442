import ajax from '@/utils/ajax.js'


/////////////////////     PC管理     ////////////////////
/**
 * 获取分类列表
 * @param {*} params 
 * @returns 
 */
 export function getTypeList(params) {
  return ajax.post('/PcMallGoodsType/getTypeList',params)
}

/**
 * 获取子分类列表
 * @param {*} params
 * @returns
 */
 export function getSubTypeList(params) {
  return ajax.post("/PcMallGoodsType/getSonTypeList", params);
}

/**
 * 编辑分类
 * @param {*} params
 * @returns
 */
 export function editType(params) {
  return ajax.post("/PcMallGoodsType/editType", params);
}

/**
 * 编辑子分类
 * @param {*} params
 * @returns
 */
export function editSubType(params) {
  return ajax.post("/PcMallGoodsType/editSonType", params);
}

/**
 * 网站在售：全部
 * @param {*} params
 * @returns
 */
export function getPcMallAllGoods(params) {
  return ajax.post("/PcMallGoodsTypeGoods/getAllList", params);
}

/**
 * 网站在售：全部排序
 * @param {*} params
 * @returns
 */
export function sortPcMallAllGoods(params) {
  return ajax.post("/PcMallGoodsTypeGoods/allGoodsSort", params);
}

/**
 * 根据类型ID获取分类列表
 * @param {*} params
 * @returns
 */
 export function getGoodsListByTypeId(params) {
  return ajax.post("/PcMallGoodsTypeGoods/getList", params);
}


/**
 * 根据类型ID获取分类列表
 * @param {*} params
 * @returns
 */
 export function editTypeGoods(params) {
  return ajax.post("/PcMallGoodsTypeGoods/editTypeGoods", params);
}


/**
 * 添加商品可选列表
 * @param {*} params
 * @returns
 */
 export function addGoodsList(params) {
  return ajax.post("/PcMallGoodsTypeGoods/getList", params);
}



/**
 * 根据所选sku查询详细信息
 * @param {*} params
 * @returns
 */
 export function getSkuListByGoodsId(params) {
  return ajax.post("/PcMallGoodsTypeGoods/getListAddUse", params);
}
