<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="选择商城分类"
           :afterClose="remove"
           width="20%"
           @cancel="remove"
           @ok="handleOk">
    <div>
       <a-select v-model="selectType" style="width:100%;"
              placeholder="请选择商城分类">
        <a-select-option v-for="item in typeList"
              :value="item.type_id"
              :key="item.type_id">{{item.type_name}}</a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import {
  getMallTypeList,
} from "@/api/mall.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    goodsList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      selectType: [],
      typeList: [],
      typeSubList: []
    }
  },
  async mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getMallTypeList()
      if (code == 0) {
        this.typeList = data.list.map(el => {
          el.isLeaf = false
          return el
        })
      }
    },

    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      this.$router.push({path: '/mall-sell',query:{goodsId: this.goodsList.join(','), typeId: this.selectType}})
      this.$emit('update:show', false)
    },

  }
}
</script>

<style>
</style>