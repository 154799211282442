<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="选择网站分类"
           :afterClose="remove"
           width="20%"
           @cancel="remove"
           @ok="handleOk">
    <div>
      <a-cascader v-model="selectType" :field-names="{ label: 'type_name', value: 'type_id', children: 'children' }"
                  :options="typeList"
                  style="width:100%;"
                  expand-trigger="hover"
                  placeholder="请选择要添加商品的网站分类"
                  :loadData="getChildrenList" />
    </div>
  </a-modal>
</template>

<script>
import {
  getTypeList,
  getSubTypeList
} from "@/api/pc/mall-sell.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    goodsList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      selectType: [],
      typeList: [],
      typeSubList: []
    }
  },
  async mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getTypeList()
      if (code == 0) {
        this.typeList = data.list.map(el => {
          el.isLeaf = false
          return el
        })
      }
    },

    getChildrenList (node) {
      const targetOption = node[node.length - 1];
      targetOption.loading = true;

      return new Promise(() => {
        targetOption.loading = false;
        getSubTypeList({ parent_type_id: targetOption.type_id }).then((res) => {

          if (res.data.list.length > 0) {
            targetOption.children = res.data.list.map(item => {
              item.isLeaf = true
              return item
            })
          }else{
            targetOption.isLeaf = true
          }

          this.typeList = [...this.typeList]
        })
      })
    },

    onSelect (selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      this.$router.push({path: '/pc-mall-sell',query:{goodsId: this.goodsList.join(','), typeId: this.selectType.join(',')}})
      this.$emit('update:show', false)
    },

  }
}
</script>

<style>
</style>