import { goodsType, formatGoodsType } from "@/utils/type";

export const rowKey = 'goods_id'
export const columns = [ // 角色列表
  // {
  //   title: '商品编码',
  //   dataIndex: 'goods_number',
  //   align: 'center'
  // },
  {
    title: 'ID',
    dataIndex: 'goods_id',
    align: 'center',
    width: 80,
  },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center',
    slots: {
      customRender: 'goods_name'
    }
  },
  {
    title: '产品属性',
    dataIndex: 'type',
    align: 'center',
    width: "9%",
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatGoodsType(val)
  },
  {
    title: '小程序门店在售',
    dataIndex: 'is_on_sale_shop',
    align: 'center',
    width: "9%",
    slots: {
      customRender: 'is_on_sale_shop'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"是":""}
  },
  {
    title: '小程序商城在售',
    dataIndex: 'is_on_sale_mall',
    align: 'center',
    width: "9%",
    slots: {
      customRender: 'is_on_sale_mall'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"是":""}
  },
  {
    title: '官网在售',
    dataIndex: 'is_on_sale_website',
    align: 'center',
    width: "9%",
    slots: {
      customRender: 'is_on_sale_website'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"是":""}
  },
  {
    title: '创建日期',
    dataIndex: 'add_time',
    align: 'center',
    width: "13%",
    // slots: {
    //   customRender: 'add_time'
    // },
    // slotsType: 'format',
    // slotsFunc: (val) => formatDate(val)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'operation'
    }
  },
]

// 基本SKU列数据
export const skuColumns = [
  {
    title: '口味',
    dataIndex: 'flavor_name',
    align: 'center',
    slots: {
      customRender: 'flavor_name'
    },
  },
  {
    title: '尺寸',
    dataIndex: 'specification_name',
    align: 'center',
    width:120,
    slots: {
      customRender: 'specification_name'
    },
  },
  {
    title: '净含量',
    dataIndex: 'net_quantity',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'net_quantity'
    },
  },
  {
    title: 'SKU码',
    dataIndex: 'sku_number',
    align: 'center',
    width:120,
    slots: {
      customRender: 'sku_number'
    },
  },
  {
    title: '默认价',
    dataIndex: 'price',
    width:120,
    align: 'center',
    slots: {
      customRender: 'price'
    },
  },
  {
    title: '区域价',
    dataIndex: 'area_price',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'area_price'
    }
  },
  {
    title: '售卖',
    dataIndex: 'status',
    align: 'center',
    width:70,
    slots: {
      customRender: 'status'
    }
  },
  {
    title: 'SKU图片\n600*430',
    dataIndex: 'horizontal_image_path',
    align: 'center',
    width: 130,
    slots: {
      customRender: 'horizontal_image_path'
    }
  }
]

// 好茶SKU列数据
export const skuDrinkColumns = [
  {
    title: '杯型',
    dataIndex: 'specification_name',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'specification_name'
    },
  },
  {
    title: '净含量',
    dataIndex: 'net_quantity',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'net_quantity'
    },
  },
  {
    title: 'SKU码',
    dataIndex: 'sku_number',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'sku_number'
    },
  },
  {
    title: '默认价',
    dataIndex: 'price',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'price'
    },
  },
  {
    title: '区域价',
    dataIndex: 'area_price',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'area_price'
    }
  },
  {
    title: '售卖',
    dataIndex: 'status',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'status'
    }
  },
  {
    title: 'SKU图片\n600*430',
    dataIndex: 'horizontal_image_path',
    align: 'center',
    width: 130,
    slots: {
      customRender: 'horizontal_image_path'
    }
  }
]

// 蛋糕SKU列数据
export const skuCakeColumns = [
  {
    title: '口味',
    dataIndex: 'flavor_name',
    align: 'center',
    slots: {
      customRender: 'flavor_name'
    },
  },
  {
    title: '尺寸',
    dataIndex: 'specification_name',
    align: 'center',
    slots: {
      customRender: 'specification_name'
    },
  },
  {
    title: '净含量',
    dataIndex: 'net_quantity',
    align: 'center',
    slots: {
      customRender: 'net_quantity'
    },
  },
  {
    title: 'SKU码',
    dataIndex: 'sku_number',
    align: 'center',
    slots: {
      customRender: 'sku_number'
    },
  },
  {
    title: '食用人数',
    dataIndex: 'diners_number_config',
    align: 'center',
    width: 150,
    slots: {
      customRender: 'diners_number_config'
    }
  },
  {
    title: '默认价',
    dataIndex: 'price',
    align: 'center',
    width: 100,
    slots: {
      customRender: 'price'
    },
  },
  {
    title: '区域价',
    dataIndex: 'area_price',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'area_price'
    }
  },
  {
    title: '默认制作时间(h)',
    dataIndex: 'production_hour',
    align: 'center',
    slots: {
      customRender: 'production_hour'
    }
  },
  {
    title: '城市制作时间',
    dataIndex: 'production_time_city',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'production_time_city'
    }
  },
  {
    title: 'SKU图片\n600*430',
    dataIndex: 'horizontal_image_path',
    align: 'center',
    width: 130,
    slots: {
      customRender: 'horizontal_image_path'
    }
  },
  {
    title: '售卖',
    dataIndex: 'status',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'status'
    }
  }
]

export const salmodel_columns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'goods_id',
  //   align: 'center',
  //   width: 80,
  // },
  {
    title: '产品属性',
    dataIndex: 'type',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatGoodsType(val)
  },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center'
  },
  {
    title: '小程序门店在售',
    dataIndex: 'is_on_sale_shop',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'is_on_sale_shop'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"是":""}
  },
  {
    title: '小程序商城在售',
    dataIndex: 'is_on_sale_mall',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'is_on_sale_mall'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"是":""}
  },
  // {
  //   title: '官网在售',
  //   dataIndex: 'is_on_sale_website',
  //   align: 'center',
  //   width: 80,
  //   slots: {
  //     customRender: 'is_on_sale_website'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"是":""}
  // },
  // {
  //   title: '创建日期',
  //   dataIndex: 'add_time',
  //   align: 'center',
  //   width: "13%",
  // },
  {
    title: '销售政策',
    dataIndex: 'sale_model_desc',
    align: 'center',
    // width: "13%",
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'operation'
    }
  },
]

export default {
  rowKey,
  columns,
  skuColumns,
  skuDrinkColumns,
  skuCakeColumns,
  salmodel_columns,
  goodsType,
  formatGoodsType
}
